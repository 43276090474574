///
/// @file global/_mixins.scss
///
/// \brief Mixins are helpful snippets that let us not repeat ourselves
///
/// Mixins output css and style rules. Don't use a mixin where you could use
/// @extend instead.
///

///
/// Font Smoothing
/// Primarily used to improve rendering of light text on dark background
///
@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

///
/// Style input placeholders
///
@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
    opacity: 1 !important;
  } //opacity needed to override FF user agent
  &::-moz-placeholder {
    @content;
    opacity: 1 !important;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

///
/// Style input placeholders
///
@mixin input-styled-check($icon, $suffix: '_checked', $padding: 5px) {
  // hide the actual element
  position: absolute;
  left: -9999px;
  ~ label,
  ~ .label {
    &::before {
      margin-right: $padding;
      content: '';
      width: 13px;
      height: 13px;
      background: url('#{$base-theme-path}img/icons/src/' + $icon + '.svg') no-repeat;
      background-size: cover;
      display: inline-block;
      vertical-align: middle;
    }
  }
  &:checked {
    ~ label,
    ~ .label {
      &::before {
        margin-right: $padding;
        content: '';
        width: 13px;
        height: 13px;
        background: url('#{$base-theme-path}img/icons/src/' + $icon + $suffix + '.svg') no-repeat;
        background-size: cover;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
// Addendum --
// Expected HTML:
// Either this:
// <input type="checkbox" id="thecheckbox">
// <label for="thecheckbox">Label Text</label>
//
// Or this:
// <label>
//   <input type="checkbox">
//   <span class="label">Label Text</span>
// </label>

///
/// Retina Images
///
/// Usage:
/// @include image-2x('logo2x.png', 100px, 25px);
/// Note: Retina images should follow naming convention of 'image@2x.png'
///
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: image-url($image);
    background-size: $width $height;
  }
}

///
/// Font Smoothing
/// Primarily used to improve rendering of light text on dark background
///
@mixin antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

///
/// Circles
///
/// Usage:
/// @include circle(200px, #999);
///
@mixin circle($default-circle-width: 41px, $color: #ccc) {
  width: $default-circle-width;
  height: $default-circle-width;
  background: $color;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

///
/// Triangles
/// Add "position: relative" for the parent element.
///
/// Usage:
/// @include css-arrow(position, cut, color, outline color, size);
///
/// Examples:
/// @include css-arrow(bottom, null, #000, null, 20px); // Use "null" if not cut or outline
/// @include css-arrow(bottom, right, #000, #999, 20px); // Use "null" if not cut or outline
///
@mixin arrow($arrow-direction, $arrow-cut, $arrow-color, $arrow-color-outline, $arrow-size) {
  $arrow-direction-opposite: nth((top, right, bottom, left), index((bottom, left, top, right), $arrow-direction));
  &:after {
    position: absolute;
    content: ' ';
    top: auto;
    left: auto;
    height: 0;
    width: 0;
    margin: 0;
    border: solid $arrow-size transparent;
    pointer-events: none;
  }
  &:after {
    border-#{$arrow-direction}: none;
    border-#{$arrow-direction-opposite}: solid $arrow-size $arrow-color;
    margin-left: (-$arrow-size);
    left: 50%;
  }
  @if $arrow-cut != null {
    &:after {
      border-#{$arrow-cut}: $arrow-size / 2;
    }
  }
  @if $arrow-color-outline != null {
    &:before {
      position: absolute;
      content: ' ';
      top: auto;
      left: auto;
      height: 0;
      width: 0;
      margin: 0;
      border: solid ($arrow-size + 1px) transparent;
      pointer-events: none;
      border-#{$arrow-direction}: none;
      border-#{$arrow-direction-opposite}: solid ($arrow-size + 1px) $arrow-color-outline;
      margin-left: -($arrow-size + 1px);
      left: 50%;
    }
  }
  @if $arrow-cut != null and $arrow-color-outline != null {
    &:before {
      border: solid ($arrow-size + 2px) transparent;
      border-#{$arrow-direction-opposite}: solid ($arrow-size + 2px) $arrow-color-outline;
    }
    &:after,
    &:before {
      border-#{$arrow-cut}: $arrow-size / 2;
    }
  }
}

///
/// Vertical centerer
/// Source: http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/
///
@mixin vertical-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

///
/// Hide from both screenreaders and browsers: h5bp.com/u
///
@mixin hidden {
  display: none !important;
  visibility: hidden;
}

@mixin mobile_hidden {
  display: none;
  @include breakpoint($medium-up) {
    display: block;
  }
}

@mixin ajaxSpinner($color, $width, $height, $borderwidth) {
  width: $width;
  height: $height;
  border: $borderwidth solid $color;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  -ms-animation: spin 1s linear infinite;
  -o-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@mixin wrap {
  max-width: $max-width;
  padding: 0 20px;
  position: relative;
  margin: 0 auto;
  @include breakpoint($large-up) {
    padding: 0 16px;
  }
}

// adaptive-placeholder
// --------------------
// - Creates adaptive placeholder

@mixin adaptive-placeholder($map: $adaptive-placeholder) {
  // Only overwrite ap defaults if $map is $adaptive-placeholder
  @if $map == $adaptive-placeholder {
    $ap-default: map-merge($ap-default, $map) !global;
    $map: $ap-default;
  }
  // Updates default map with new values
  $map: map-merge($ap-default, $map);
  // Gets variables
  $height: map-get($map, height);
  $margin: map-get($map, margin);
  $border: map-get($map, border);
  $radius: map-get($map, border-radius);
  $font-size: map-get($map, font-size);
  $border-color: map-get($map, border-color);
  $label-color: map-get($map, label-color);
  $active-color: map-get($map, active-color);
  $valid-color: map-get($map, valid-color);
  $invalid-color: map-get($map, invalid-color);
  $placeholder-background: map-get($map, placeholder-background-color);
  $isTextarea: map-get($map, textarea);
  $borders: $border * 2;

  // Warnings for best practices
  @if $height < 2 * $margin {
    @warn "height needs to be at least 2x margin";
  }
  @if $height < $font-size {
    @warn "height needs to be at least >= font-size";
  }

  // Input / Textarea properties
  background-color: $placeholder-background;
  height: $height;
  height: calc(#{$height} - -#{$borders});
  padding: $margin;
  font-size: $font-size;
  border-radius: $radius;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  -webkit-box-shadow: none;
  box-shadow: none;
  @include input-placeholder {
    opacity: 0;
    color: transparent;
  }
  resize: none;
  outline: none;
  &:hover {
  }
  &:focus,
  &:active {
    & + label:before {
      color: $active-color;
    }
  }
  // Placeholder Styles
  & + label {
    display: block;
    font-size: $font-size;
    span.label-content {
      display: none;
    }

    // Vertically align label with input
    @if $isTextarea {
      height: $height;
      height: calc(#{$height} - -#{$borders});
      line-height: 1;
      padding-top: $margin;
      padding-top: calc(#{$margin} - -#{$border});
    } @else {
      height: $height;
      line-height: $height;
    }

    // Offset label correctly with negative margins
    @if $isTextarea {
      margin-top: $height;
      margin-top: calc(-#{$height} - #{$borders});
    } @else {
      margin-top: $height;
      margin-top: calc(-#{$height} - #{$border});
    }
    margin-bottom: 1em;
    cursor: text;
    &:before {
      // Initial Text
      content: attr(placeholder);
      display: inline-block;
      color: $label-color;
      margin: 0 $margin;
      margin: 0 calc(#{$margin} - -#{$borders});
      white-space: nowrap;
      // Transformation properties
      -webkit-transition-property: -webkit-transform, color;
      transition-property: transform, color;
      -webkit-transition-duration: 0.2s;
      transition-duration: 0.2s;
      -webkit-transition-delay: 0;
      transition-delay: 0;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
      // Set origin to left center so it scales to the left instead of center center
      -webkit-transform-origin: left center;
      -ms-transform-origin: left center;
      transform-origin: left center;

      // Heres a version without prefixes
      // transition-property: transform, color;
      // transition-duration: 0.2s;
      // transition-delay: 0;
      // transition-timing-function: ease-out;
      // // Set origin to left center so it scales to the left instead of center center
      // transform-origin: left center;
    }
  }

  // Focused, active and valid states for input and placeholder
  // Shifts up and adds a background white
  &:focus,
  &.active,
  &.js-label-mode {
    & + label:before {
      background: $placeholder-background;
      // set padding for left & right of tiny placeholder text
      line-height: 1;
      padding: $border * 0.5 $border * 1.5;
      // Adjusted all calcs within translates according to to split up into various translateYs instead of using calc for IE 10 support
      // http://stackoverflow.com/questions/21469350/not-possible-to-use-css-calc-with-transformtranlatex-in-ie
      @if $isTextarea {
        // transform: translateY(calc((-#{$margin} - 0.5em) - #{$border * 0.5})) scale(0.8, 0.8);
        -webkit-transform: translateY(-$margin - 0.5em) translateY(-$border * 0.5) scale(0.8, 0.8);
        transform: translateY(-$margin - 0.5em) translateY(-$border * 0.5) scale(0.8, 0.8);
      } @else {
        -webkit-transform: translateY(-$height * 0.5) translateY(-$border * 0.5) scale(0.8, 0.8);
        transform: translateY(-$height * 0.5) translateY(-$border * 0.5) scale(0.8, 0.8);
      }
    }
  }
  // Change placeholder value once text is entered.
  &.js-label-mode {
    & + label:before {
      content: attr(alt);
    }
  }
  &:required {
    &.error,
    &:invalid {
      & + label:before {
        color: $invalid-color;
      }
      &:empty,
      &[value=''],
      &:not(:checked),
      &.empty {
        & + label:before {
          color: $label-color;
        }

        // for some reason, if I group &.error, &.touched, and &.checkedEmpty, magic_js chokes on the CSS linking
        &.error,
        &.touched {
          & + label:before {
            color: $label-color;
          }
        }
        &.checkedEmpty {
          & + label:before {
            color: $invalid-color;
          }
        }
        &:focus {
          & + label:before {
            color: $active-color;
          }
        }
      }
    }
    &:valid {
      & + label:before {
        color: $valid-color;
      }
      &.error {
        & + label:before {
          color: $valid-color;
        }
      }
    }
    &[data-error] {
      & + label:before {
        color: $invalid-color;
      }
    }
  }
  &:focus {
    & + label:before {
      color: $active-color;
    }
  }
}

@mixin checkout-section-header {
  @include h24;
  text-indent: $checkout-spacing;
  margin: 0;
  padding-bottom: 0;
  line-height: 50px;
  border-bottom: 1px solid $color-gray-border-section;
  .active + .collapsed &, // closed > open
  .finished + .collapsed &, // closed > open
  .finished + .active &, // open > open
  .finished + .finished &, // open > open
  .finished + .hidden + .collapsed &, // open > hidden > open
  .finished + .disabled + .collapsed &, // open > hidden > open
  .active + .disabled + .disabled + .collapsed &, // open > hidden > hidden > open
  .active + .hidden  + .hidden + .collapsed &, // open > hidden > hidden > open
  .active + .disabled + .hidden + .collapsed &, // open > hidden > hidden > open
  .active + .hidden  + .disabled + .collapsed &, // open > hidden > hidden > open
  .finished + .disabled + .disabled + .collapsed &, // open > hidden > hidden > open
  .finished + .hidden + .hidden + .collapsed &, // open > hidden > hidden > open
  .finished + .disabled + .hidden + .collapsed &, // open > hidden > hidden > open
  .finished + .hidden + .disabled + .collapsed & {
    // open > hidden > hidden > open
    border-top: 1px solid $color-gray-border-section;
  }
  .collapsed + .collapsed &, // closed > closed
  .collapsed + .disabled + .collapsed &, // closed > hidden > closed
  .collapsed + .hidden + .collapsed &, // closed > hidden > closed
  .collapsed + .disabled + .disabled + .collapsed &, // closed > hidden > hidden > closed
  .collapsed + .hidden + .hidden + .collapsed &, // closed > hidden > hidden > closed
  .collapsed + .disabled + .hidden + .collapsed &, // closed > hidden > hidden > closed
  .collapsed + .hidden + .disabled + .collapsed & {
    // closed > hidden > hidden > closed
    border-top: none;
  }
  a {
    @include link-reset;
  }
  .collapsed & {
    color: $color-beige-dark;
  }
}

// Typography mixins
@mixin main-font {
  font-family: $main-font;
}

@mixin main-font-light {
  font-family: $main-font;
  font-weight: 300;
  font-style: normal;
}

@mixin main-font-light-italic {
  @include main-font-light;
  font-style: italic;
}

@mixin main-font-medium {
  font-family: $main-font;
  font-weight: 500;
  font-style: normal;
}

@mixin main-font-medium-italic {
  @include main-font-medium;
  font-style: italic;
}

@mixin main-font-light {
  font-family: $main-font;
  font-weight: 300;
  font-style: normal;
}

@mixin main-font-light-italic {
  @include main-font-light;
  font-style: italic;
}

@mixin main-font-bold {
  font-family: $main-font;
  font-weight: 700;
  font-style: normal;
}

@mixin main-font-bold-italic {
  @include main-font-bold;
  font-style: italic;
}

@mixin main-font-book {
  font-family: $main-font;
  font-weight: 400;
  font-style: normal;
}

@mixin main-font-book-italic {
  @include main-font-book;
  font-style: italic;
}

@mixin main-font-thin {
  font-family: $main-font;
  font-weight: 100;
  font-style: normal;
}

@mixin main-font-thin-italic {
  @include main-font-thin;
  font-style: italic;
}

// hx mixin for @include when using breakpoints
// %hx for @extend
// .hx when using on markup, do not @extend

// h1
@mixin h1 {
  font-family: $main-font;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  margin: 0 0 17px 0;
  @include breakpoint($medium-up) {
    font-size: 39px;
    line-height: 47px;
  }
}

// h2
@mixin h2 {
  font-family: $main-font;
  font-weight: normal;
  font-size: 37px;
  line-height: 46px;
  letter-spacing: 0.04em;
}

// h3
@mixin h3 {
  font-family: $main-font;
  font-weight: normal;
  font-size: 21px;
  line-height: 28px;
  letter-spacing: 0.04em;
  @include breakpoint($medium-up) {
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.034em;
  }
}

// h4
@mixin h4 {
  font-family: $main-font;
  font-weight: 400;
  font-style: normal;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.031em;
  text-transform: uppercase;
  @include breakpoint($medium-up) {
    font-size: 24px;
  }
}

// h5
@mixin h5 {
  font-family: $main-font;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 0.01em;
  line-height: 35px;
  color: $color-black;
  @include breakpoint($medium-up) {
    font-size: 22px;
    color: $color-black;
  }
}

// h6
@mixin h6 {
  font-family: $main-font;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.06em;
  @include breakpoint($medium-up) {
    line-height: 25px;
  }
}

// h7
@mixin h7 {
  font-family: $main-font;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.06em;
  @include breakpoint($medium-up) {
    font-size: 16px;
    line-height: 30px;
  }
}

// h8
@mixin h8 {
  font-family: $main-font;
  font-weight: 700;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0;
  margin-bottom: 4px;
  text-transform: uppercase;
  @include breakpoint($medium-up) {
    font-size: 14px;
  }
}

// h9
@mixin h9 {
  font-family: $main-font;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  @include breakpoint($medium-up) {
    letter-spacing: 0.06em;
    line-height: 14px;
  }
}

// h10
@mixin h10 {
  font-family: $main-font;
  font-weight: 500;
  font-size: 18px;
  line-height: 31px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
}

// h11
@mixin h11 {
  font-family: $main-font;
  font-weight: normal;
  font-size: 21px;
  line-height: 46px;
  letter-spacing: 0.04em;
}

// h12
@mixin h12 {
  font-family: $main-font;
  font-weight: 500;
  font-size: 11px;
  line-height: 19px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

// h13
@mixin h13 {
  font-family: $main-font;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  margin: 0 auto 30px auto;
  padding: 0 33px;
  &:before {
    @include breakpoint($small-up) {
      background: none;
      content: none;
    }
    @include breakpoint($medium-up) {
      background: $color-black;
      display: inline-block;
      height: 2px;
      left: 0;
      position: absolute;
      top: 43%;
      width: 100%;
    }
  }
  .inner,
  &__inner {
    @include breakpoint($small-up) {
      background: $color-white;
      display: inline-block;
      position: relative;
      padding: 0 7px 0 9px;
    }
    @include breakpoint($medium-up) {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.2em;
      padding: 0 7px 0 9px;
    }
  }
  @include breakpoint($small-up) {
    font-size: 13px;
    font-weight: bold;
    padding: 0;
    margin: 30px auto;
  }
  @include breakpoint($medium-up) {
    font-size: 14px;
    padding: 0 55px;
  }
}

// h14
@mixin h14 {
  font-family: $main-font;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.01em;
}

// h15
@mixin h15 {
  font-family: $main-font;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.01em;
}

// h16
@mixin h16 {
  font-family: $main-font;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  @include breakpoint($medium-up) {
    font-size: 14px;
  }
}

// h17
@mixin h17 {
  font-family: $main-font;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.01em;
}

// h18
@mixin h18 {
  font-family: $main-font;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.24em;
  text-transform: uppercase;
}

// h19
@mixin h19 {
  font-family: $main-font;
  font-weight: 500;
  font-size: 21px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
}

// h20
@mixin h20 {
  font-family: $main-font;
  font-weight: 700;
  font-size: 11px;
  line-height: 50px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
}

// h21
@mixin h21 {
  font-family: $main-font;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.01em;
}

// h22
@mixin h22 {
  font-family: $main-font;
  font-weight: 500;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

// h23
@mixin h23 {
  font-family: $main-font;
  font-weight: 400;
  font-size: 9px;
  line-height: 15px;
  letter-spacing: 0.01em;
}

// h24
@mixin h24 {
  font-family: $main-font;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

// h25
@mixin h25 {
  font-family: $main-font;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

// h26
@mixin h26 {
  font-family: $main-font;
  font-weight: normal;
  font-size: 21px;
  letter-spacing: 0.24em;
  text-transform: uppercase;
}

// button mixin

@mixin button {
  @include font-smoothing(antialiased);
  background-color: $base-button-color;
  color: $color-white;
  display: inline-block;
  font-family: $main-font;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  padding: 5px 27px 6px 13px;
  text-decoration: none;
  letter-spacing: 1px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: $hover-button-color;
    color: white;
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
}

@mixin link($color-active, $color-hover, $touch: true) {
  color: $color-active;
  cursor: pointer;
  display: inline-block;
  font-family: $main-font;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.14em;
  line-height: 19px;
  padding-bottom: 5px;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: top;
  &:hover {
    color: $color-hover;
    border-color: $color-hover;
    text-decoration: none;
  }
  @if $touch {
    //not always outlined
    .touch & {
      border-style: none;
      border-width: 2px;
      padding: 10px;
      text-decoration: none;
    }
  }
}

@mixin l1 {
  @include link($color-black, $color-black);
}

@mixin l2 {
  @include link($color-black, $color-black);
}

@mixin l3 {
  color: $color-black;
}

// header + footer
@mixin l4 {
  @include transition(color 0.2s ease-in-out, border 0.2s ease-in-out, background-color 0.2s ease-in-out);
  color: $color-black;
  text-decoration: none;
  &:hover {
    color: $hover-link-color;
  }
  &:active,
  &:focus {
    outline: none;
  }
}

// used in accounts
@mixin l5 {
  font-family: $main-font;
  font-size: 11px;
  font-weight: 500;
  color: $color-black;
  line-height: 25px;
  border-bottom: 1px solid $color-black;
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

@mixin l7 {
  @include link($color-black, $color-black);
  &::after {
    content: '';
    width: 10px;
    height: 12px;
    background: url('#{$base-theme-path}img/icons/src/arrow_right.svg') no-repeat;
    background-size: cover;
    display: inline-block;
    .expanded & {
      transform: rotate(90deg);
      padding-top: 4px;
    }
  }
}

@mixin link-reset {
  color: $color-black;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

@mixin button--secondary {
  @include button;
  border: 1px solid $color-black;
  background: url('/media/images/global/arrow_right_white.png') no-repeat center right 11px $color-black;
  &:hover {
    color: $color-black;
    background: url('/media/images/global/arrow_right_black.png') no-repeat center right 11px $color-white;
  }
}

@mixin input-button--secondary {
  border: 1px solid $color-black;
  background: url('/media/images/global/arrow_right_white.png') no-repeat center right 11px $color-black;
  &:hover {
    color: $color-black;
    background: url('/media/images/global/arrow_right_black.png') no-repeat center right 11px $color-white;
  }
}

@mixin button--checkout {
  @include button;
  padding: 5px 27px 6px 13px;
  border: 1px solid $color-black;
  background: url('/media/images/global/arrow_right_white.png') no-repeat center right 11px $color-black;
  &:hover {
    color: $color-black;
    background: url('/media/images/global/arrow_right_black.png') no-repeat center right 11px $color-white;
  }
}

/// RTL support

$rdirection: right !default;
$ldirection: left !default;
$text-direction: ltr !default;

@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}
// 2019 Redesign
// Arrows and dots colors
@mixin carousel-arrows-color($color: $color-black) {
  .icon-arrow--light {
    display: none;
  }
  @if ($color == $color-gray-medium) {
    .icon-arrow--black {
      display: none;
    }
    .icon-arrow--light {
      display: block;
    }
  }
}

@mixin carousel-dots-color($color: $color-black) {
  .slick-dots {
    li {
      height: 12px;
      width: 12px;
      margin: 0 7px;
      @include breakpoint($medium-up) {
        margin: 0 5px;
      }
      button {
        &:before {
          border-radius: 50%;
          border: 2px solid $color;
          background: none;
          width: 12px;
          height: 12px;
          font-size: 0;
          @include breakpoint($medium-up) {
            border: 1px solid $color;
          }
        }
      }
      &.slick-active {
        button {
          &:before {
            background: $color;
          }
        }
      }
    }
  }
}

// New styleguide / typography mixins - applied in helper classes

// Title - xl
@mixin text-title--xl {
  font-family: $header-font-bold;
  font-size: 34px;
  letter-spacing: -0.02em;
  line-height: get-line-height(34px, 36px);
  @include breakpoint($medium-up) {
    font-size: 56px;
    letter-spacing: -0.04em;
    line-height: get-line-height(56px, 58px);
  }
}
// Title - large
@mixin text-title--large {
  font-family: $header-font-bold;
  font-size: 26px;
  letter-spacing: -0.02em;
  line-height: get-line-height(26px, 28px);
  @include breakpoint($medium-up) {
    font-size: 40px;
    letter-spacing: -0.03em;
    line-height: get-line-height(40px, 42px);
  }
}
// Title - med
@mixin text-title--med {
  font-family: $header-font-bold;
  font-size: 22px;
  letter-spacing: -0.01em;
  line-height: get-line-height(22px, 24px);
  @include breakpoint($medium-up) {
    font-size: 20px;
    line-height: get-line-height(20px, 22px);
  }
}
// Title - small
@mixin text-title--small {
  font-family: $header-font-bold;
  font-size: 20px;
  letter-spacing: -0.01em;
  line-height: get-line-height(20px, 22px);
  @include breakpoint($medium-up) {
    font-size: 14px;
    line-height: get-line-height(14px, 16px);
  }
}

@mixin text-title--smaller {
  // used for Sub-Product Name
  font-family: $header-font-bold;
  font-size: 18px;
  line-height: get-line-height(18px, 16px);
  @include breakpoint($medium-up) {
    font-size: 12px;
    line-height: get-line-height(12px, 14px);
  }
}

// Body text - default
@mixin text-body-text {
  font-family: $main-font;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: get-line-height(14px, 16px);
}

// Body text - tag style
@mixin text-body--tags {
  font-family: $header-font-bold;
  font-size: 22px;
  letter-spacing: 0.08em;
  line-height: get-line-height(22px, 24px);
  @include breakpoint($medium-up) {
    font-size: 16px;
    line-height: get-line-height(16px, 18px);
  }
}

// Paragraph P1 text
@mixin text-paragraph--p1 {
  font-family: $main-font;
  font-size: 24px;
  letter-spacing: 0.04em;
  line-height: get-line-height(24px, 26px);
  @include breakpoint($medium-up) {
    font-size: 16px;
    line-height: get-line-height(16px, 18px);
  }
}

// Paragraph P2 text
@mixin text-paragraph--p2 {
  font-family: $header-font-bold;
  font-size: 22px;
  letter-spacing: 0.08em;
  line-height: get-line-height(22px, 24px);
  @include breakpoint($medium-up) {
    font-size: 16px;
    line-height: get-line-height(16px, 18px);
  }
}

// EM text
@mixin text-em {
  font-family: $header-font-bold;
  font-size: 22px;
  letter-spacing: 0.04em;
  line-height: get-line-height(22px, 24px);
  @include breakpoint($medium-up) {
    font-size: 14px;
    line-height: get-line-height(14px, 16px);
  }
}

// Callout text
@mixin text-callout {
  font-family: $header-font-bold;
  font-size: 14px;
  letter-spacing: 0.08em;
  line-height: get-line-height(14px, 16px);
}

// 2019 Redesign
// New styleguide / Button mixins - applied in helper classes
// Button base

@mixin elc-button {
  background-image: none;
  border: 2px solid transparent;
  cursor: pointer;
  display: inline-block;
  font-family: $header-font-bold;
  font-size: 14px;
  height: 40px;
  line-height: get-line-height(14px, 40px);
  min-width: auto;
  padding: 0 15px;
  text-align: center;
  text-transform: uppercase;
  width: auto;
  &:hover {
    background-image: none;
  }
  @include breakpoint($medium-up) {
    padding: 0 2em;
  }
}
// @todo remove after react component cleanup
// temporary fixes to address presentational styles coming directly from react components
@mixin elc-button-component-overrides {
  letter-spacing: unset;
  padding: 0;
  border-radius: 0;
}
// Primary Button
@mixin elc-button--pink {
  background-color: $cr19-bg-pink;
  color: $cr19-text-white;
  &:visited {
    color: $cr19-text-white;
  }
  &:hover {
    background-color: $cr19-bg-black;
    border-color: $cr19-bg-black;
    color: $cr19-text-white;
  }
  &:active {
    background-color: $cr19-dark-gray;
    border-color: $cr19-bg-black;
    color: $cr19-text-white;
  }
}
// Secondary Button
@mixin elc-button--inverted {
  background-color: $cr19-bg-white;
  border-color: $cr19-bg-black;
  color: $cr19-text-black;
  &:visited {
    color: $cr19-text-black;
  }
  &:hover,
  &:active {
    background-color: $cr19-bg-pink;
    border-color: $cr19-bg-pink;
    color: $cr19-text-white;
  }
}

// 2019 Redesign
// New styleguide / Link style mixins - applied in helper classes
// Variants are meant to be loaded after base mixin
// Link base

@mixin text-link {
  color: $cr19-text-black;
  cursor: pointer;
  display: inline-block;
  font-family: $main-font;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: get-line-height(14px, 16px);
  padding-bottom: 5px;
  text-decoration: none;
  vertical-align: top;
  &:hover {
    color: $cr19-text-pink;
  }
}
// Link - Style 1 - Dark, Grey on hover
@mixin text-link--style-1 {
  font-family: $header-font-bold;
}
// Link - Style 2 - Dark with underline
@mixin text-link--style-2 {
  font-family: $main-font;
}

// Container related rules
@mixin container-max-width-full {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: 100%;
  width: 100%;
}

@mixin container-max-width-landscape {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: $container-max-width-landscape;
  width: 100%;
}

@mixin container-max-width-desktop-x-wide {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: $desktop-x-wide;
  width: 100%;
}

@mixin container-max-width-desktop-wide {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: $desktop-wide;
  width: 100%;
}

@mixin container-max-width-desktop {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: $desktop;
  width: 100%;
}

@mixin container-max-width-tablet {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: $tablet;
  width: 100%;
}

@mixin container-padding-rules {
  padding-#{$ldirection}: $container-pad-small;
  padding-#{$rdirection}: $container-pad-small;
  width: 100%;
  @include breakpoint($landscape-up) {
    padding-#{$ldirection}: $container-pad-landscape;
    padding-#{$rdirection}: $container-pad-landscape;
  }
}

// 2019 Redesign
// New styleguide / Form elements mixins - applied in helper classes

@mixin input-new {
  border: 0;
  border-radius: 0;
  display: block;
  float: none;
  font-family: $main-font;
  font-size: 14px;
  height: 50px;
  letter-spacing: 5px;
  line-height: get-line-height(14px, 18px);
  margin: 10px 0 15px;
  padding: 0;
  text-transform: uppercase;
  vertical-align: middle;
}

@mixin input-field {
  border-bottom: 1px solid $cr19-bg-dark;
  color: $cr19-text-dark-gray;
  &:focus,
  &:active {
    color: $cr19-text-black;
  }
  @include input-placeholder {
    color: $color-gray-dark;
  }
}

@mixin input-error {
  border-bottom: 1px solid $cr19-error;
  color: $cr19-error;
  @include input-placeholder {
    color: $cr19-error;
  }
}

// Outline style on focused elements for keyboard accessibility.
@mixin outline {
  outline: 1px solid $color-focus !important;
}

@mixin float-left-text-right {
  float: left;
  text-align: right;
}

// Skeleton Shimmer Animations
@mixin shimmer($duration: 1s, $easing: linear) {
  animation: elc-shimmer $duration 60 $easing;
  backface-visibility: hidden;
  background: $color-shimmer-grey-light
    linear-gradient(
      -45deg,
      $color-shimmer-grey-light 0%,
      $color-shimmer-grey-light 40%,
      $color-shimmer-grey 50%,
      $color-shimmer-grey-light 60%,
      $color-shimmer-grey-light 100%
    );
  background-size: 250% 250%;
  perspective: 1000px;
  transform: rotateZ(360deg);
  will-change: transform;
  [dir='rtl'] & {
    animation: elc-shimmer-rtl $duration 60 $easing;
    backface-visibility: hidden;
    background: $color-shimmer-grey-light
      linear-gradient(
        45deg,
        $color-shimmer-grey-light 0%,
        $color-shimmer-grey-light 40%,
        $color-shimmer-grey 50%,
        $color-shimmer-grey-light 60%,
        $color-shimmer-grey-light 100%
      );
    background-size: 250% 250%;
    perspective: 1000px;
    transform: rotateZ(360deg);
    will-change: transform;
  }
}