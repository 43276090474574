@import '../../../../scss/theme-bootstrap';

.hero-content-block {
  &__content-wrapper {
    &--pc {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
        width: 100%;
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        height: 100%;
      }
      .content-block__content {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }
    &--mobile {
      text-align: center;
      @include breakpoint($medium-up) {
        display: none;
      }
      .hero-content-block__content-text {
        padding: 40px 15px 0;
      }
    }
  }
  &__content-text {
    p {
      margin: 0;
      padding: 0;
    }
  }
  .content-block__line--eyebrow {
    margin-bottom: 5px;
  }
  .content-block__line--headline {
    margin-bottom: 10px;
  }
  .content-block__line--content {
    margin-bottom: 15px;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include breakpoint($medium-down) {
      color: $cr19-text-black;
    }
  }
  &__inner {
    @include breakpoint($medium-up) {
      position: relative;
    }
  }
}

.countdown-content {
  margin: 30px 0;
  @include breakpoint($medium-up) {
    @include swap_direction(margin, 40px 0 20px 0);
  }
  .countdown_timer {
    color: $color-black;
    font-size: 38px;
    margin: 10px 0;
    @include breakpoint($medium-up) {
      color: $color-white;
      font-size: 77pt;
      font-family: $header-font-bold;
    }
  }
  + .content-block {
    &__line {
      &--content {
        margin-bottom: 20px;
        .title {
          &--med {
            p {
              font-size: 16pt;
              font-family: $base-font-family;
            }
          }
          &--small {
            p {
              font-size: 24pt;
              font-family: $base-font-family;
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
}

.countdowntimer {
  margin-bottom: 20px;
}

.carousel-formatter__container {
  .carousel__slide {
    &:not(:first-child) {
      .hero-content-block__inner.preloaded {
        display: none;
      }
    }
  }
}
